html{
  overflow-x: hidden;
  max-width:100%;
}

.mainHeader{
    font-size:3vw;
    letter-spacing:5px;
    margin-top:0;
}
.subHeader{
    font-size:1.5vw;
    margin-bottom:0;
    font-weight:bold;
    letter-spacing:5px;

}
label{
  font-weight:500 !important;
}
.badge{
  font-weight: bold;
}
.mainNav{
  background-color: rgb(39, 48, 54);
  height: 52.5px !important;
}
.triangle-end {
  position: absolute;
  left: 94.4%;
  top: 0px;
  display: inline-block;
  width: 0; 
  height: 0; 
  border-bottom: 63px solid transparent;
  /* adjust border-left for overall width */
  border-left: 63px solid rgb(39, 48, 54);
}
.triangle-before {
  position: absolute;
  right: 55.5%;
  top: 0px;
  display: inline-block;
  width: 0; 
  height: 0; 
  border-bottom: 63px solid transparent;
  /* adjust border-left for overall width */
  border-right: 63px solid rgb(39, 48, 54);
}
.mainNav p, .mainNav a, .navUser span{
  margin-bottom:0 !important;
  font-weight:bold !important;
  margin-top:5px;
  font-size:14px !important;
}
.marginIgnore{
  margin-top: -1px !important;
}
.marginUser{
  margin-top: 2px !important;
}
.mainNav a, .navUser span{
  color: #fff !important;
}
.mainNav p:hover{
  opacity:0.5;
}

@media only screen and (max-width: 600px) {
  .mainHeader{
    font-size:9vw;
    letter-spacing:0px;
    margin-top:0;
    padding: 0 10px;
  }

  .subHeader{
      font-size:6vw;
      margin-bottom:0;
      font-weight:bold;
      letter-spacing:10px;

  }
}
@media only screen and (min-width: 2340px) {
  .mainHeader{
    font-size:2vw;
  }

  .subHeader{
      font-size:1vw;
  }
}
.radio-group{
  flex-wrap:wrap;
}
.normal{
  font-weight:400;
}
.width100{
  width:100% !important;
}
.avatarMin{
  min-width:75px !important;
}
.blue {
    color: #0070F3 !important;
  }
.scroll-container{
  overflow-x:scroll;
  overflow-y:hidden;
}
  .link{
      cursor:pointer;
      color: #0070f3 !important;
  }
  .link:hover{
      text-decoration: underline;
  }
.pointer{
  cursor:pointer;
}
.layoutSVG{
    position:fixed;
    z-index:0;
    bottom:0;
}
.back-to-top {
  position: fixed !important;
  right: 2em;
  bottom: 2em;
  border-radius: 100% !important;
  background: #141c38;
  padding: 0.5rem 1rem 2.5rem !important;
  border: none;
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.5s;
  z-index:9;
}

.back-to-top:hover {
  opacity: 60%;
}

.hidden {
  opacity: 0%;
}
.filepond--drop-label, .filepond--panel-root {
  background: none !important;
  border-radius: 5px;
  border: 1px solid #333;
}
.back-to-top-icon {
  width: 1rem;
  height: 1rem;
  color: #7ac9f9;
}
.profileImg .avatar{
    width: calc(1.75 * 75px) !important;
    height: calc(1.75 * 75px) !important;
}
.input-container{
  border-radius: 5px;
}
textarea{
  border-radius: 5px;
  background: none !important;
}
#mobileNav{
  background: black;
  width:55%;
}

.white{
  color: white !important;
}

#mobileNav .names span{
  color: white !important;
  font-weight:bold;
}
#mobileNav a, #mobileNav p{
  color: white;
  font-weight:bold !important;
}
.custom-radios  {
    margin-bottom:15px;
  }

.custom-radios  div {
    display: inline-block;
  }

  .custom-radios input[type="radio"] {
    display: none;
  }
  .custom-radios input[type="radio"] + label {
      color: #333;
      font-family: Arial, sans-serif;
      font-size: 14px;
  }
      .custom-radios input[type="radio"] + label span {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #FFFFFF;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        line-height: 44px;
      }
      .custom-radios strong{
          font-size:18px;
          margin-left:5px;
      }
        .custom-radios input[type="radio"] + label img {
          opacity: 0;
          transition: all .3s ease;
        }
        .custom-radios .color-3 + label span {
            background-color: #e3e3e3;
            transition:0.3s;
            margin-bottom: 15px;
          }
        .custom-radios .color-3 + label span:hover {
            transition:0.3s;
            background-color: rgba(52, 152, 219, 0.5)!important;
          }
          .custom-radios input[type="radio"]:checked + label span {
            background-color: #3498db !important;
          }
          .custom-radios input[type="radio"]:checked + label span img {
            opacity: 1;
            width: 22px;
            height: auto;
          }
          .text-center{
              text-align: center;
          }
          .bold{
              font-weight:bold;
          }
          .signInBg{
              background: url(./Images/signin.jpg);
              border-left: 1px solid #eaeaea;
          }
          .cardCSS{
              position:relative;
              z-index:2;
          }          
          .cardCSS > div:nth-of-type(1){
            padding:0;
        }
        label{
            font-size:14px !important;
            margin-bottom:5px;
        }
        .error{
            color: #f33446 !important;
            margin-top:5px;
            margin-left:2.5px;
            font-size:12px;
        }

        .tabActive{
          text-decoration: underline;
        }
        .recharts-legend-item::before{
          content: "";
        }
        .m-0 {
            margin: 0 !important;
          }
          
          .mt-0,
          .my-0 {
            margin-top: 0 !important;
          }
          
          .mr-0,
          .mx-0 {
            margin-right: 0 !important;
          }
          
          .mb-0,
          .my-0 {
            margin-bottom: 0 !important;
          }
          
          .ml-0,
          .mx-0 {
            margin-left: 0 !important;
          }
          
          .m-1 {
            margin: 0.25rem !important;
          }
          
          .mt-1,
          .my-1 {
            margin-top: 0.25rem !important;
          }
          
          .mr-1,
          .mx-1 {
            margin-right: 0.25rem !important;
          }
          
          .mb-1,
          .my-1 {
            margin-bottom: 0.25rem !important;
          }
          
          .ml-1,
          .mx-1 {
            margin-left: 0.25rem !important;
          }
          
          .m-2 {
            margin: 0.5rem !important;
          }
          
          .mt-2,
          .my-2 {
            margin-top: 0.5rem !important;
          }
          
          .mr-2,
          .mx-2 {
            margin-right: 0.5rem !important;
          }
          
          .mb-2,
          .my-2 {
            margin-bottom: 0.5rem !important;
          }
          
          .ml-2,
          .mx-2 {
            margin-left: 0.5rem !important;
          }
          
          .m-3 {
            margin: 1rem !important;
          }
          
          .mt-3,
          .my-3 {
            margin-top: 1rem !important;
          }
          
          .mr-3,
          .mx-3 {
            margin-right: 1rem !important;
          }
          
          .mb-3,
          .my-3 {
            margin-bottom: 1rem !important;
          }
          
          .ml-3,
          .mx-3 {
            margin-left: 1rem !important;
          }
          
          .m-4 {
            margin: 1.5rem !important;
          }
          
          .mt-4,
          .my-4 {
            margin-top: 1.5rem !important;
          }
          
          .mr-4,
          .mx-4 {
            margin-right: 1.5rem !important;
          }
          
          .mb-4,
          .my-4 {
            margin-bottom: 1.5rem !important;
          }
          
          .ml-4,
          .mx-4 {
            margin-left: 1.5rem !important;
          }
          
          .m-5 {
            margin: 3rem !important;
          }
          
          .mt-5,
          .my-5 {
            margin-top: 3rem !important;
          }
          
          .mr-5,
          .mx-5 {
            margin-right: 3rem !important;
          }
          
          .mb-5,
          .my-5 {
            margin-bottom: 3rem !important;
          }
          
          .ml-5,
          .mx-5 {
            margin-left: 3rem !important;
          }
          
          .p-0 {
            padding: 0 !important;
          }
          
          .pt-0,
          .py-0 {
            padding-top: 0 !important;
          }
          
          .pr-0,
          .px-0 {
            padding-right: 0 !important;
          }
          
          .pb-0,
          .py-0 {
            padding-bottom: 0 !important;
          }
          
          .pl-0,
          .px-0 {
            padding-left: 0 !important;
          }
          
          .p-1 {
            padding: 0.25rem !important;
          }
          
          .pt-1,
          .py-1 {
            padding-top: 0.25rem !important;
          }
          
          .pr-1,
          .px-1 {
            padding-right: 0.25rem !important;
          }
          
          .pb-1,
          .py-1 {
            padding-bottom: 0.25rem !important;
          }
          
          .pl-1,
          .px-1 {
            padding-left: 0.25rem !important;
          }
          
          .p-2 {
            padding: 0.5rem !important;
          }
          
          .pt-2,
          .py-2 {
            padding-top: 0.5rem !important;
          }
          
          .pr-2,
          .px-2 {
            padding-right: 0.5rem !important;
          }
          
          .pb-2,
          .py-2 {
            padding-bottom: 0.5rem !important;
          }
          
          .pl-2,
          .px-2 {
            padding-left: 0.5rem !important;
          }
          
          .p-3 {
            padding: 1rem !important;
          }
          
          .pt-3,
          .py-3 {
            padding-top: 1rem !important;
          }
          
          .pr-3,
          .px-3 {
            padding-right: 1rem !important;
          }
          
          .pb-3,
          .py-3 {
            padding-bottom: 1rem !important;
          }
          
          .pl-3,
          .px-3 {
            padding-left: 1rem !important;
          }
          
          .p-4 {
            padding: 1.5rem !important;
          }
          
          .pt-4,
          .py-4 {
            padding-top: 1.5rem !important;
          }
          
          .pr-4,
          .px-4 {
            padding-right: 1.5rem !important;
          }
          
          .pb-4,
          .py-4 {
            padding-bottom: 1.5rem !important;
          }
          
          .pl-4,
          .px-4 {
            padding-left: 1.5rem !important;
          }
          
          .p-5 {
            padding: 3rem !important;
          }
          
          .pt-5,
          .py-5 {
            padding-top: 3rem !important;
          }
          
          .pr-5,
          .px-5 {
            padding-right: 3rem !important;
          }
          
          .pb-5,
          .py-5 {
            padding-bottom: 3rem !important;
          }
          
          .pl-5,
          .px-5 {
            padding-left: 3rem !important;
          }

          .error-toast {
            background: #F7D4D6 !important;
          }
          .info-toast {
            background: #BBDEFB !important;
          }
          .Toastify__toast-body {
            color: #10171D; /* #10171D */
            font-size: 0.875rem !important;
          }
          .error-toast .Toastify__toast-body {
            color: #10171D; /* #10171D */
            font-size: 0.875rem !important;
          }
          .Toastify__progress-bar {
            background: #333F48 !important;
          }
          .tags-input input{
            padding: 0 10px !important;
          }
          #tags {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
          }
          
          .tag, .formMultiContainer {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            list-style: none;
            border-radius: 6px;
            margin: 0 8px 8px 0;
            background: #0052cc;
          }
          
          .tag{
            padding: 0 8px;
            height: 32px;
          }
          
          .formMultiContainer {
            padding: 5px 8px;
          }
          
          .tag-title {
            margin-top: 3px;
          }
          
          .tag-close-icon, .formMultiDelete {
            display: block;
            padding: 3px 4px 0;
            line-height: 16px;
            text-align: center;
            font-size: 10px;
            margin: 3px 0px 2px 8px;
            color: #0052cc;
            border-radius: 50%;
            cursor: pointer;
            opacity:0.75;
            color: white !important;
          }
          .tag-close-icon, .formMultiDelete:hover {
            opacity:1;
          }
          .point{
            border: 1px solid #757575 !important;
          }
          .avatar{
            overflow:visible !important;
          }
          .tagDiv{
            background:#eee !important;
            padding:5px 10px !important;
            border-radius:5px;
            margin-left:5px;
            color: black;
          }
          .react-reveal{
            position:relative;
            z-index:2;
          }